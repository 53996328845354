(function ($) {
  Drupal.behaviors.ELC_pageUtilites = {
    attach: function (context, settings) {
      $('.page-utilities__signup .js-email-signup-submit', context).on('click', function () {
        var currObj = $(this);
        var dest = currObj.attr('data-dest');

        dest = dest || '';
        if (/^\/.+$/.test(dest)) {
          dest = dest.replace(/^(\s|\/)+/, '');
        }
        if (/^(?!http|\/).+$/.test(dest)) {
          dest = Drupal.settings.basePath + dest;
        }
        // var email = document.getElementsByName("search");
        var emailvalue = currObj.parent().find('.site-email-signup__field').val();

        if (emailvalue !== currObj.parent().find('#default').val() && emailvalue !== '') {
          dest = dest + '?email=' + escape(emailvalue);
          currObj.parent().find('.offers-email-signup-form__errors').addClass('hidden');
          window.location.href = dest;
        } else {
          currObj.parent().find('.offers-email-signup-form__errors').removeClass('hidden');
        }

        return false;
      });
    }
  };
})(jQuery);
